<template>
    <div id="offline" class="hide-scrollbar">
        <div class="w50 left"></div>
        <div class="w50 right">
            <div class="title">Whoops!</div>
            <div class="message">Parece que he perdido la conexión. Revisa que el dispositivo esté conectado y pruébalo de nuevo</div>
            <div class="button" :class="[try_again_error ? 'error' : '']" @click="tryAgain()">Probar de nuevo</div>
        </div>
    </div>
</template>

<script>
import localforage from 'localforage'

export default {
    name: 'Offline',
    data() {
        return {
            try_again_error: false
        }
    },
    computed: {
        language() {
            return this.$store.getters['getLanguage']
        },
        languages() {
            return this.$store.getters['getLanguages']
        }
    },
    created() {},
    mounted() {
        document.getElementById('fullcontent').className = 'inOffline'
    },
    methods: {
        tryAgain() {
            log(fnCheckConnection())
            if (fnCheckConnection()) {
                const config = this.$store.getters['login/getConfig']
                var path = config && config.home ? config.home : '/home'
                log(this.$store.getters.getLanguage)
                this.$store.commit('changeLanguage', this.$store.getters.getLanguage)
                this.$router.push(this.$root.redirectPath(path))
            } else {
                var self = this
                this.try_again_error = true
                setTimeout(function() {
                    self.try_again_error = false
                }, 1000)
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#offline {
    @include display-flex();
    @include flex-direction(row);
    @include justify-content();
    @include align-items();
    background-color: $default-bg-color;
    background-image: img('circuit-board.svg');
    height: 100%;
    width: 100%;
    overflow: auto;

    .w50 {
        width: 50%;
        float: left;
        overflow: hidden;

        &.left {
            @include background($image: img('alexpose_04.svg'), $position: right 0px center, $size: 20vw);
            height: 100%;
        }

        &.right {
            .title {
                font-size: 40px !important;
                font-weight: bold;
                @include background($image: img('no_signal_alert.svg'), $position: left 220px center, $size: 30px);
            }
            .message {
                margin-top: 40px;
                max-width: 275px;
                font-size: 17px;
            }

            .button {
                /*margin-top: 50px;
        width: 250px;
        text-align: center;
        background-color: blue;
        padding: 10px;
        border-radius: 2px;
        color: white;
        font-weight: bold;*/

                margin-top: 50px;
                @include background($color: $main);
                @include font-size(sm);
                text-transform: uppercase;
                text-align: center;
                padding: 10px;
                width: 250px;
                height: 40px;
                color: white;

                &:hover {
                    background-color: rgba($main, 0.8);
                    cursor: pointer;
                }

                &.error {
                    background-color: $error;
                    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
</style>
